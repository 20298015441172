<script>
// import Loader from "vue-element-loading";
import Form from "ant-design-vue/lib/form";
import axios from "axios";
import AInputNumber from "ant-design-vue/lib/input-number";
import AInput from "ant-design-vue/lib/input";
import ATextarea from "ant-design-vue/lib/input/TextArea";
import Message from "ant-design-vue/lib/message";
import AFormModel from "ant-design-vue/lib/form-model";
import AFormModelItem from "ant-design-vue/lib/form-model/FormItem";
import ACol from "ant-design-vue/lib/grid/Col";
import ARow from "ant-design-vue/lib/grid/Row";
export default {
  components: {
    AInputNumber,
    ACol,
    ARow,
    ATextarea,
    AFormModel,
    AFormModelItem,
    AInput,
  },
  data() {
    return {
      loading: false,
      form: Form.createForm(this),
      name: "",
      email: "",
      message: "",
      captchaText:
        "First, a brief presidential level IQ test, just so we know you're human.",
      formAreaMsg:
        "We'd love to hear from you, Unless you're a bot - in that case:",
      binaryText: "101001100101001",
      options: [{}],
      challenge: null,
      challengeAnswer: null,
      messageSent: false,
      challengeDisabled: false,
    };
  },
  methods: {
    send() {
      this.loading = true;
      const postData = {
        name: this.name,
        email: this.email,
        message: this.message,
      };

      axios.post("/api/new_message", postData).then(() => {
        this.messageSent = false;
        Message.success(
          "Thanks for your message! We have received and will respond! ",
          3
        ).then(() => {
          this.$router.push({ name: "Home" });
        });
      });
    },
    onChange(val) {
      this.challengeAnswer = val;
    },
    handleChange() {
      console.log("this");
    },
    confirmChallenge() {
      if (!this.challenge) {
        return false;
      }

      this.challengeDisabled = true;
      if (this.challengeAnswer == this.challenge.total) {
        this.$store.commit("Auth/SET_PASSED_CHALLENGE");
        return;
      } else {
        Message.info("Ah, able to try again? :(");
      }

      setTimeout(() => {
        this.challengeDisabled = false;
      }, 1000).bind(this);
    },
  },
  computed: {
    disableButton() {
      if (this.name && this.email && this.message) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.loading = true;
    this.$store.commit("Nav/SET_PAGE", "challenge");
    axios.get("/api/new_challenge").then(({ data }) => {
      this.challenge = data;
      this.loading = false;
    });
  },
};
</script>
<template>
  <div class="my-container">
    <div
      id="challenge"
      class="row justify-content-center"
      v-if="!$store.state.Auth.passedChallenge"
    >
      <div class="col-6">
        <div class="row mt-3">
          <div class="col">
            <h1 class="captcha-text">{{ captchaText }}</h1>
          </div>
        </div>
        <template v-if="challenge">
          <div class="row mt-2">
            <div class="col">
              What is
              <span class="text-danger font-weight-bold">{{
                challenge.firstText
              }}</span>
              {{ challenge.op }}
              <span class="text-danger font-weight-bold">{{
                challenge.secondText
              }}</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <a-input-number
                size="large"
                :min="1"
                :max="100000"
                v-on:keyup.enter="confirmChallenge"
                v-model="challengeAnswer"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <a
                class="login-step-btn"
                :disabled="challengeDisabled"
                @click="confirmChallenge"
                >I'm Human!</a
              >
            </div>
          </div>
        </template>
        <!-- </div> -->
      </div>
    </div>
    <div class="contact-form-area" v-else>
      <h1 class="contact-form-area__msg">
        {{ formAreaMsg }}
        <span class="contact-form-area__msg--binary-text">{{ binaryText }}</span
        >!
      </h1>
      <div class="contact-form-area__form">
        <a-form-model
          :form="form"
          layout="vertical"
          enctype="multipart/form-data"
        >
          <a-row :gutter="64">
            <a-col :span="12">
              <a-form-model-item :model="name">
                <h1 class="label" slot="label">Name</h1>
                <a-input v-model="name"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :model="name">
                <h1 class="label" slot="label">Email Address</h1>
                <a-input type="email" v-model="email"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-model-item :model="name">
                <h1 class="label" slot="label">Your Message</h1>
                <a-textarea
                  v-model="message"
                  :rows="10"
                  placeholder="Type your message here"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-model-item>
                <a class="login-step-btn" @click.prevent="send">
                  <i class="fas fa-envelope icon icon-left"></i> Send Message
                </a>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/styles/_components.scss";
@import "../assets/styles/_utilities.scss";
@import "../assets/styles/_mixins.scss";
.my-container {
  padding-top: 7.6rem;
}
.my-container,
input,
.btn,
textarea,
.form-control {
  font-size: 14px;
}
.captcha-text {
  font-family: "Anton", sans-serif;
}
#challenge {
  margin-top: 8rem;
  text-align: center;
}
.contact-form-area {
  margin-top: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__msg {
    font-family: "Anton", sans-serif;
    font-size: 1.9rem;
    &--binary-text {
      font-family: "Source Sans Pro", sans-serif;
      color: var(--bluetiful);
    }
    @include respond(tab_l) {
    }
    @include respond(tab_p) {
    }
    @include respond(phone) {
      font-size: 1.6rem;
      width: 32rem;
    }
  }
  &__form {
    margin-top: 1.4rem;
    width: 62rem;
    @include respond(tab_l) {
    }
    @include respond(tab_p) {
    }
    @include respond(phone) {
      width: 32rem;
    }
  }
  @include respond(tab_l) {
  }
  @include respond(tab_p) {
  }
  @include respond(phone) {
    margin-top: 4.6rem;
  }
}
.label {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.7rem;
  color: var(--color-grey-dark-1);
  @include respond(tab_l) {
  }
  @include respond(tab_p) {
  }
  @include respond(phone) {
    font-size: 1.7rem;
  }
}
</style>

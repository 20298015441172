<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$store.commit("Nav/SET_PAGE", "privacy-policy");
  }
};
</script>
<template>
  <div class="privacy-policy my-container">
    <h1 class="title">Privacy Policy</h1>
    <h2 class="policy-heading">Lorem ipsum dolor sit.</h2>
    <p>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestias,
      perferendis? Quidem culpa aperiam consectetur eaque qui? Inventore illum
      aspernatur sed cum iusto vero, molestiae deserunt quia quis at optio
      adipisci! Consequatur excepturi animi ullam magnam pariatur consequuntur
      atque, odio quidem nulla perferendis aliquam totam obcaecati similique!
      Similique, soluta error dolor consequuntur odio maiores, aspernatur nisi
      eaque totam quisquam minus quibusdam! Maiores quas molestias illum
      necessitatibus explicabo non recusandae, quod alias labore, aspernatur
      unde consequuntur beatae eius hic architecto id neque pariatur libero
      perferendis odio. Consectetur necessitatibus ea veritatis sequi fuga!
    </p>
    <h2 class="policy-heading">Lorem ipsum dolor sit.</h2>
    <p>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestias,
      perferendis? Quidem culpa aperiam consectetur eaque qui? Inventore illum
      aspernatur sed cum iusto vero, molestiae deserunt quia quis at optio
      adipisci! Consequatur excepturi animi ullam magnam pariatur consequuntur
      atque, odio quidem nulla perferendis aliquam totam obcaecati similique!
      Similique, soluta error dolor consequuntur odio maiores, aspernatur nisi
      eaque totam quisquam minus quibusdam! Maiores quas molestias illum
      necessitatibus explicabo non recusandae, quod alias labore, aspernatur
      unde consequuntur beatae eius hic architecto id neque pariatur libero
      perferendis odio. Consectetur necessitatibus ea veritatis sequi fuga!
    </p>
    <h2 class="policy-heading">Lorem ipsum dolor sit.</h2>
    <p>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestias,
      perferendis? Quidem culpa aperiam consectetur eaque qui? Inventore illum
      aspernatur sed cum iusto vero, molestiae deserunt quia quis at optio
      adipisci! Consequatur excepturi animi ullam magnam pariatur consequuntur
      atque, odio quidem nulla perferendis aliquam totam obcaecati similique!
      Similique, soluta error dolor consequuntur odio maiores, aspernatur nisi
      eaque totam quisquam minus quibusdam! Maiores quas molestias illum
      necessitatibus explicabo non recusandae, quod alias labore, aspernatur
      unde consequuntur beatae eius hic architecto id neque pariatur libero
      perferendis odio. Consectetur necessitatibus ea veritatis sequi fuga!
    </p>
    <h2 class="policy-heading">Lorem ipsum dolor sit.</h2>
    <p>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestias,
      perferendis? Quidem culpa aperiam consectetur eaque qui? Inventore illum
      aspernatur sed cum iusto vero, molestiae deserunt quia quis at optio
      adipisci! Consequatur excepturi animi ullam magnam pariatur consequuntur
      atque, odio quidem nulla perferendis aliquam totam obcaecati similique!
      Similique, soluta error dolor consequuntur odio maiores, aspernatur nisi
      eaque totam quisquam minus quibusdam! Maiores quas molestias illum
      necessitatibus explicabo non recusandae, quod alias labore, aspernatur
      unde consequuntur beatae eius hic architecto id neque pariatur libero
      perferendis odio. Consectetur necessitatibus ea veritatis sequi fuga!
    </p>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/styles/_components.scss";
@import "@/assets/styles/_mixins.scss";

.privacy-policy {
  font-family: var(--open-sans);
  //   margin-top: 18.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //   align-items: center;
}
.my-container {
  margin: 8.8rem 9rem;
}
.title {
  margin-bottom: 3.3rem;
  align-self: left;
  font-size: 3.8rem;
  font-weight: 600;
  color: var(--color-lapis-blue);
}
.policy-heading {
  font-size: 2.3rem;
  font-weight: 600;
  margin-bottom: 2.1rem;
}

p {
  font-size: 1.7rem;
  max-width: 110ch;
  margin-bottom: 2.8rem;
}
</style>
